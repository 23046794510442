@font-face {
    font-family: lato-light;
    src: url(../fonts/Lato-Light.ttf);
}

@font-face {
    font-family: lato-reg;
    src: url(../fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato-bold;
    src: url(../fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato-italic;
    src: url(../fonts/Lato-Italic.ttf);
}