@use 'variables';

body, html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
}

h1, h2, h3, h4, h5, h6 , p , a , input, label, textarea, form, button {
    padding: 0;
    margin: 0;
}

a, li, ul, ol {
    text-decoration: none;
    list-style-type: none;
    list-style: none;
}

button {
    outline: none;
    border: none;
}

a, button {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6, a, button {
    font-family: lato-bold, "Helvetica", sans-serif;
    color: variables.$secondary;
}

p, span, input, textarea {
    font-family: lato-reg, "Helvetica", sans-serif;
}