@mixin flexbox(
    $justify: null, 
    $align: null, 
    $direction: null, 
    $wrap: null, 
    $order: null,
    $grow: null, 
    $shrink: null, 
    $basis: null,
    ) {
        display: flex;
        justify-content: $justify;
        align-items: $align;
        flex-direction: $direction;
        flex-wrap: $wrap;
        order: $order;
        flex: $grow $shrink $basis
};

@mixin flexCentered {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin position (
    $top: null, $right: null, $bottom: null, $left: null, $transform: null
) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    transform: $transform;
}

@mixin positionAbsoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin resetPosition {
    top: 0;
    left: 0;
    transform: translate(0,0);
}

@mixin resetWidthHeight {
    height: auto;
    width: auto;
}

@mixin sameWidthHeight (
    $size
) {
    height: $size;
    width: $size;
}

@mixin fullWidthHeight {
    width: 100%;
    height: 100%;
}

@mixin fontStyles (
    $fontSize: null,
    $color: null,
    $fontWeight: null,
    $textAlign: null,
    $letterSpace: null,
    $lineHeight: null,
    $wordWrap: null,
    $fontStyle: null,
    $textDecoration: null,
    $textTransform: null
) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    text-align: $textAlign;
    letter-spacing: $letterSpace;
    line-height: $lineHeight;
    word-wrap: $wordWrap;
    font-style: $fontStyle;
    text-decoration: $textDecoration;
    text-transform: $textTransform;
}

@mixin backgroundWithOpacity (
    $rgbColor, $opacity
) {
    background: rgba($rgbColor , $opacity);
}

@mixin backgroundStyles (
    $background: null,
    $backgroundImage: null,
    $backgroundSize: null,
    $backgroundPosition: null,
    $backgroundRepeat: null,
    $boxShadow: null,
) {
    background: $background;
    background-image: $backgroundImage;
    background-size: $backgroundSize;
    background-position: $backgroundPosition;
    background-repeat: $backgroundRepeat;
    box-shadow: $boxShadow;
}

@mixin transition (
    $elements: all, $duration: 0, $timing: null,
) {
    transition: $elements $duration $timing;
    transition-duration: $elements $duration $timing;
    -webkit-transition-duration: $elements $duration $timing;
}

@mixin clearSpaces {
    margin: 0;
    padding: 0;
}

@mixin clearCustomOuterElements($border: null, $outline: null, $boxShadow: null, $background: null) {
    border: $border;
    outline: $outline;
    box-shadow: $boxShadow;
    background: $background;
}

@mixin clearOuterElements {
    outline: 0;
    box-shadow: none;
}