@use '../../assets/styles/mixins';
@use '../../assets/styles/variables';
@use '../../assets/styles/media-queries';

.rounded-btn {
    @include mixins.flexCentered;
    @include mixins.sameWidthHeight(40px);
    border-radius: 6px;
    background-color: variables.$primary;
    cursor: pointer;
    transition: all .25s ease-out;

    &:last-of-type {
        margin-left: 15px;
    }

    & img {
        width: 16px;
        display: block;
    }

    & img[src*="info-solid"] {
        width: 10px;
    }

    &:hover {
        opacity: 0.85;
    }

    &:active {
        opacity: 1;
    }

    @include media-queries.responsive(tablet) {

    }
}
