// Colors
$primary: #001a39;
$secondary: #333333;
$mainBackgroundColor: #F2F1E6;
$whiteColor: #ffffff;
$blackColor: #000000;
$cardBoxShadow: #d0d0d0;
$ctaDarkBlue: #31689c;
$ctaLightBlue: #66DAC5;
$ctaDarkBlueHover: #3e82c3;
$ctaRegistrationHover: #3490dc;
$ctaGray: #cccccc;

// Font Weight
$light: 300;
$bold: 500;
$strongBold: 700;